<template>
  <div :class="['idex-wrap', 'desktop', ClassName, LocaleClass]">
    <pageHead device="desktop" page="layouts"/>
    <autoRefresh />
    <div class="btn-flexall">
      <!-- <div class="flex-left">
        <userModBtn v-for="(item, index) in flexLeft" v-model="flexLeft[index]" :key="index" v-if="item.type" />
      </div>
      <div class="flex-right">
        <userModBtn v-for="(item, index) in flexRight" v-model="flexRight[index]" :key="index" v-if="item.type" />
      </div> -->

      <userModBtn2 :value="FloatBtnList" />

      <!-- <btnFlexService /> -->
    </div>
    <Header></Header>
    <main>
      <idxMarquee />
      <slot name="content">
        <pageHead device="desktop" page="index"/>
        <idxSlider />
        <idxGame />
         <idxDownload />
        <!-- <idxInfo /> -->
      </slot>
    </main>
    <Footer />
    <dialogMarquee />
    <dialogMaintain />
    <dialogExchPoint v-if="ShowTransferPoint" />
    <dialogTransferPoint v-if="ShowExchPoint" />
    <overlay v-model="IsLoading"></overlay>
    <upAgentCode />
    <slot name="other"/>
  </div>
</template>
<script>
  const Header = require(`~/components/desktop/${process.env.pc_header}.vue`).default;
  const idxMarquee = require(`~/components/desktop/${process.env.pc_marquee}.vue`).default;
  const idxGame = require(`~/components/desktop/${process.env.pc_game}.vue`).default;
  const idxDownload = require(`~/components/desktop/${process.env.pc_idxDownload}.vue`).default;
  const idxInfo = require(`~/components/desktop/${process.env.pc_info}.vue`).default;
  const Footer = require(`~/components/desktop/${process.env.pc_footer}.vue`).default;

  import axios from "axios";
  let flexLeft = [];
  try { flexLeft = require("$json/flexLeft.json"); } catch (e) {}
  let flexRight = [];
  try { flexRight = require("$json/flexRight.json"); } catch (e) {}
  let floatBtn = [];
  try { floatBtn = require("$json/floatBtn.json"); } catch (e) {}
  import pageHead from "~/components/common/pageHead.vue";
  import autoRefresh from "~/components/common/autoRefresh.vue";
  import userModBtn from "~/components/desktop/userModBtn.vue";
  import userModBtn2 from "~/components/desktop/userModBtn2.vue";
  import btnFlexService from "~/components/desktop/btnFlexService.vue";
  import idxSlider from "~/components/desktop/idxSlider2.vue";
  import dialogMarquee from "~/components/desktop/dialogMarquee.vue";
  import dialogMaintain from "~/components/desktop/dialogMaintain.vue";
  import dialogExchPoint from "~/components/desktop/dialogExchPoint.vue";
  import dialogTransferPoint from "~/components/desktop/dialogTransferPoint.vue";
  import overlay from "@/components/common/overlay.vue";
  import upAgentCode from "@/components/common/upAgentCode.vue";
  import { mapState, mapActions, mapGetters } from "vuex";
  export default {
    components: {
      pageHead,
      autoRefresh,
      btnFlexService,
      Header,
      userModBtn,
      userModBtn2,
      idxMarquee,
      idxSlider,
      idxGame,
      idxDownload,
      idxInfo,
      Footer,
      dialogMaintain,
      dialogMarquee,
      dialogExchPoint,
      dialogTransferPoint,
      overlay,
      upAgentCode
    },
    data(){
      return{
        flexLeft,
        flexRight,
        floatBtn,
        LocaleClass: 'lang-'
      }
    },
    created() {
      if (this.floatBtn == null  || this.floatBtn.length == 0)
        this.floatBtn = getJsonFile(process.env.DEF_webStyle, 'floatBtn');
    },
    mounted() {
      this.$nextTick(function () {
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      });
    },
    methods: {
      scrollGoTop(){
        window.scrollTo({ top: 0 });
      }
    },
    watch: {
      "$route.path": function(path) {
        if(path !== '/')
          this.scrollGoTop();
      },
      "$i18n.locale": function(test) {
        this.LocaleClass = 'lang-' + this.$i18n.locale;
      }
    },
    computed: {
      ShowTransferPoint() {
        return this.$auth.$state.loggedIn === true;
      },
      ShowExchPoint() {
        return this.$auth.$state.loggedIn === true && this.WebSetting.SHOW_CONVERSION === "1";
      },
      ClassName() {
        let ModalClass = { 'idex-wrap': true, 'desktop': true };
        if (!this.FestivalClass)
          return ModalClass;

        return Object.assign(ModalClass, this.FestivalClass);
      },
      FloatBtnList() {
        return this.floatBtn.map((Item) => {
          if (Item.data && Item.data.length > 0) {
            Item.data.forEach((Value, Key) => {
              if (Value.to && Value.to == '/share/share')
                Item.data[Key].show = this.ShowShare;
            });
          }

          return Item;
        });
      },
      ...mapState(['WebSetting', 'IsLoading']),
      ...mapGetters('webSetting', ['FestivalClass', 'ShowShare'])
    }
  };
</script>
<style scoped>
  header{
    width: 100%;
  }
</style>
