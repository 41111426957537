<template>
  <header>
    <!-- 登入後 -->
    <template v-if="$auth.$state.loggedIn === true">
      <div class="nav-header after">
        <div class="mid_box01 dd_sticky">
          <idxMarquee04 />
          <nuxt-link to="/" class="logo"></nuxt-link>
          <div class="pc_navbox">
            <div class="pc_loginbox">
              <modNavRtA />
              <vuei18n />
            </div>
            <div class="nav-afterbox">
              <modNavCtrA />
            </div>
          </div>

          <template v-if="pc_header_li === '1'">
            <div class="pc_menu_s1">
              <div class="pc_menubox01">
                <nuxt-link to="/activity/activity" class="pc_menu_sort game-gactivity">
                  <i class="icon icon-gift"></i>
                  <span>{{ $t('store.activity') }}</span>
                </nuxt-link>
              </div>

              <div v-for="(item, index) in MobileMeun" :key="index" class="pc_menubox01" >
                <nuxt-link :to="item.url" class="pc_menu_sort" :class="item.images">
                  <i :class="item.class"></i>
                  <span>{{ $t(item.title) }}</span>
                </nuxt-link>
                <!-- 下-->
                <div class="idx-game">
                  <div class="idx-game-sbox">
                  <template v-for="link in item.tabs">
                    <template v-if="[3, 5, 7, 8].indexOf(item.id) >= 0">
                      <a
                        @click="ToEgame(item.id, link.Id)"
                        :class="`game-list product_${link.Id} ${link.Maintain}`"
                        :data-text="$t('store.uphold_ing')"
                        >
                          {{ link.GameName }}
                      </a>
                    </template>
                    <!--<template v-else-if="[6].indexOf(item.id) >= 0">
                      <div :class="`game-list box ${link.Maintain}`" :data-text="$t('store.uphold_ing')">
                        <i :class="`game-${link.WalletCode}`"></i>
                        <span>{{ link.GameName }}</span>
                        <img :src="link.ImgPath" :alt="link.GameName" :title="link.GameName">
                        <div class="game-btn-group">
                          <a class="btn btn-danger" @click="playGmae(item.id, link.Id, link.Maintain)">
                            {{ $t("store.btn.play_game_now") }}
                          </a>
                          <a v-if="link.TryGame" @click="tryGame(item.id, link.Id, link.Maintain)" class="btn btn-warning">
                            {{ $t("store.btn.free_try_play") }}
                          </a>
                        </div>
                      </div>
                    </template>-->
                    <template v-else-if="[6].indexOf(item.id) >= 0">
                      <div class="dn"></div>
                    </template>
                    <template v-else>
                      <a
                        @click="playGmae(item.id, link.Id, link.Maintain)"
                        :class="`game-list product_${link.Id} ${link.Maintain}`"
                        :data-text="$t('store.uphold_ing')"
                      >
                        {{ link.GameName }}
                      </a>
                    </template>
                  </template>
                  </div>
                </div>
                <!-- 下 -->
              </div>
            </div>
          </template>

        </div>
      </div>
    </template>




    <!-- 登入前 -->
    <template v-if="$auth.$state.loggedIn !== true">
      <div class="nav-header before">
        <div class="mid_box01 dd_sticky">
          <idxMarquee04 />
          <nuxt-link to="/" class="logo"></nuxt-link>
          <div class="pc_navbox">
            <div class="pc_loginbox">
              <template v-if="DEF_webStyle === 'sgph'">
                <b-button class="license" @click="showImage = true" >
                  License
                </b-button>
                <div class="licensebox" v-if="showImage">
                  <div class="license-img">
                    <img :src="require(`@/website/${DEF_webStyle}/images/license-1.jpg`)" />
                    <button @click="closeImage" class="license-X" >X</button>
                  </div>
                </div>
              </template>
              <modNavCtrB />
              <modNavRtB />
              <vuei18n />
            </div>

            <template v-if="pc_header_li === '1'">
              <div class="pc_menu_s1">
                <div class="pc_menubox01">
                  <nuxt-link to="/activity/activity" class="pc_menu_sort game-gactivity">
                    <i class="icon icon-gift"></i>
                    <span>{{ $t('store.activity') }}</span>
                  </nuxt-link>
                </div>
                <div v-for="(item, index) in MobileMeun" :key="index" class="pc_menubox01" >
                  <nuxt-link :to="item.url" class="pc_menu_sort" :class="item.images">
                    <i :class="item.class"></i>
                    <span>{{ $t(item.title) }}</span>
                  </nuxt-link>
                  <!-- 下-->
                  <div class="idx-game">
                    <div class="idx-game-sbox">
                    <template v-for="link in item.tabs">
                      <template v-if="[3, 5, 7, 8].indexOf(item.id) >= 0">
                        <a
                          @click="ToEgame(item.id, link.Id)"
                          :class="`game-list product_${link.Id} ${link.Maintain}`"
                          :data-text="$t('store.uphold_ing')"
                          >
                            {{ link.GameName }}
                        </a>
                      </template>
                      <!--<template v-else-if="[6].indexOf(item.id) >= 0">
                        <div :class="`game-list box ${link.Maintain}`" :data-text="$t('store.uphold_ing')">
                          <i :class="`game-${link.WalletCode}`"></i>
                          <span>{{ link.GameName }}</span>
                          <img :src="link.ImgPath" :alt="link.GameName" :title="link.GameName">
                          <div class="game-btn-group">
                            <a class="btn btn-danger" @click="playGmae(item.id, link.Id, link.Maintain)">
                              {{ $t("store.btn.play_game_now") }}
                            </a>
                            <a v-if="link.TryGame" @click="tryGame(item.id, link.Id, link.Maintain)" class="btn btn-warning">
                              {{ $t("store.btn.free_try_play") }}
                            </a>
                          </div>
                        </div>
                      </template>-->
                      <template v-else-if="[6].indexOf(item.id) >= 0">
                        <div class="dn"></div>
                      </template>
                      <template v-else>
                        <a
                          @click="playGmae(item.id, link.Id, link.Maintain)"
                          :class="`game-list product_${link.Id} ${link.Maintain}`"
                          :data-text="$t('store.uphold_ing')"
                        >
                          {{ link.GameName }}
                        </a>
                      </template>
                    </template>
                    </div>
                  </div>
                  <!-- 下 -->
                </div>
              </div>
            </template>

          </div>
        </div>
      </div>
    </template>
  </header>
</template>
<script>
  const vuei18n = require(`~/components/${process.env.DEF_language}.vue`).default;
  import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
  import modNavRtA from "~/components/desktop/header/modNavRtA.vue";
  import modNavCtrA from "~/components/desktop/header/modNavCtrA.vue";
  import modNavCtrB from "~/components/desktop/header/modNavCtrB.vue";
  import modNavRtB from "~/components/desktop/header/modNavRtB.vue";
  import idxMarquee04 from "~/components/desktop/idxMarquee.vue";
  export default {
    components:{
      modNavCtrA,
      modNavCtrB,
      modNavRtA,
      modNavRtB,
      vuei18n,
      idxMarquee04,
    },
    data(){
      return{
        ProductId: "",
        base: [],
        showImage: false,
      }
    },
    mounted() {
      if (process.env.DEF_webStyle === 'db11' || 'sgph' ) {
        this.headersticky();
      }
      this.init();
      this.changeLang();
    },
    methods: {
      closeImage() {
        this.showImage = false;
      },
      Login() {
        this.$router.replace({ path: "/account/login" });
      },
      playGmae(ProductId, GameId, Maintain) {
        if (Maintain != "")
          return;

        if (this.$auth.$state.loggedIn === false) {
          this.Login();
          return;
        }

        let GameData = this.Product(ProductId).find(element => element.Id === GameId);
        if (!this.ShowTransferModal) {
          window.open(GameData.Url);
          return;
        }

        this.setPlayGameData(GameData);
        this.$nextTick(() => this.$root.$emit("bv::toggle::modal", "modalExchPoint", "#btnToggle"));
      },
      tryGame(ProductId, GameId, Maintain) {
        if (this.$auth.$state.loggedIn === false)
          this.Login()
        else {
          let GameData = this.Product(ProductId).find(element => element.Id === GameId);
          window.open(GameData.UrlTry);
        }
      },
      ToEgame(CategoryId,ProductId) {

        let GoReplace;
        if (CategoryId == '3') {
          GoReplace = { path: '/game/egame', query: { ProductId } };
        } else if (CategoryId == '5') {
          GoReplace = { path: '/game/chess', query: { ProductId } };
        };

        if (ProductId == '14')
          GoReplace.path = "/game/multiple";
        if (ProductId == '15')
          GoReplace.path = "/game/battle";

        this.$router.replace(GoReplace);
      },
      init() {
        if (this.ErrorMsg != "") {
          this._showMsgBox({ title: "", msg: this.ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      },
      headersticky() {
        window.addEventListener("scroll",function(){
          var navheader = document.querySelector(".dd_sticky");
          navheader.classList.toggle("sticky",window.scrollY > 0);
        })
      },
      baseData(){
        return this.Meun.map(function(value){
          return{
            type: 'a',
            blank: value.blank,
            class: value.class,
            subtxt: value.subtxt,
            title: value.title,
            url: value.url
          }
        })
      },
      changeLang(){
        this.base = this.baseData();
      },
      ...mapActions(["_showMsgBox"]),
      ...mapMutations(["_setErrorMsg"]),
      ...mapMutations("game", ["setPlayGameData"]),
    },
    watch: {
      "$i18n.locale"(language) {
        this.changeLang();
        },
      'ErrorMsg' (ErrorMsg) {
        if (ErrorMsg && ErrorMsg != '') {
          this._showMsgBox({ title: "", msg: ErrorMsg }).then((res) => {
            this._setErrorMsg("");
          });
        }
      }
    },
    computed: {
      pc_header_li() {
        return process.env.pc_header_li;
      },
      DEF_webStyle() {
        return process.env.DEF_webStyle || 'default';
      },
      ...mapState(['WebSetting', 'ErrorMsg']),
      ...mapGetters(["Meun", "MobileMeun", "Product"]),
      ...mapGetters('webSetting', ['ShowTransferModal'])
    }    
  }
</script>
