<template>
  <div class="idxgame hotpro-row idxGame03">
   <h1 class="title">
    <span class="bg">{{ this.$t("store.casino_introduce") }}</span>
    <q class="dn fg">{{ this.$t("store.casino_introduce") }}</q>
   </h1>
   <div class="idxgmu" id="idxgmu">
      <div class="idxgmu-ctrl">
        <div class="idxgmu-prev" @click="slideCtrl(-1)">
          <span class="a"></span>
          <span class="b"></span>
        </div>
        <div class="idxgmu-next" @click="slideCtrl(1)">
          <span class="a"></span>
          <span class="b"></span>
        </div>
     </div>
     <transition-group name="flip" tag="ul" class="idxgmu-navlist">
       <li v-for="(item,index) in idxgmuData" :key="item.id">
          <nuxt-link :to="GameMeun[item.ref].url" class="idxgmu-nav">
            <span class="idxgmu-subnav">{{ $t(GameMeun[item.ref].title) }}</span>
            <span :class="GameMeun[item.ref].gameclass">
              <i class="dim"></i>
              <i class="hov"></i>
          </span>
        </nuxt-link>
      </li>
      
     </transition-group>
   </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return{
        timerDelay: 500,
        clickWait: false,
        autoplay: false,
        idxgmuData: [],
        timer: {}
      }
    },
    mounted() {
      this.idxgmuNav();
      if (this.autoplay) this.autoPlay();
    },
    methods: {
      autoPlay() {
        setInterval(() => { if (this.setTime) this.slideCtrl(1);}, this.timerDelay);
      },
      idxgmuNav(){
        for(let i = 0 ; i < this.GameMeun.length* 3; i++){
          let obj = {};
          obj.id = i;
          obj.ref = i % this.GameMeun.length;
          this.idxgmuData.push(obj);
        }
      },
      setTime() {
        this.timer = setTimeout(() => {
          this.clickWait = false;
        }, this.timerDelay);
      },
      stopTime() {
			  clearInterval(this.timer);
		  },
      slideCtrl( slidesToShow = 1){
        if(this.clickWait){
          return;
        }
        this.stopTime();
        this.clickWait = true;
        if (slidesToShow > 0) {
          const shiftItem = this.idxgmuData.shift();
          this.idxgmuData.push(shiftItem);
          this.setTime();
          return;
        }
        if (slidesToShow < 0) {
          const shiftItem = this.idxgmuData.pop();
          this.idxgmuData.unshift(shiftItem);
          this.setTime();
        }
      }

    },
    computed: {
      DEF_webStyle(){
        return process.env.DEF_webStyle;
      },
      ...mapGetters(['GameMeun']),
    }
  };
</script>